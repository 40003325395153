import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import { DocumentStep, DocumentStepData } from '@REDUCERS';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllDocuments } from '@SERVICES';

import Grid from '@COMPONENTS/SHARED/Grid';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';
import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';

import styles from './ChooseDocument.module.scss';

const cx: CX = classnames.bind(styles);

function ChooseDocumentPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const {
        data: documentsData = [], isLoading, isError, error,
    } = useGetAllDocuments();

    const navigate = useNavigate();

    function getHighlighted(id: number) {
        const step: DocumentStep = documentGenerationStepsServices.getStepData(GenerationSteps.DOCUMENT);

        return step.data?.id === id;
    }

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={130}
                    offsetBottom={60}
                >
                    <h1>Document</h1>
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Grid
                            hasSidebar
                        >
                            {
                                documentsData.map((doc) => {
                                    const key = doc.id;

                                    const highlighted = getHighlighted(doc.id);

                                    return (
                                        <SquareButton
                                            key={key}
                                            highlighted={highlighted}
                                            title={doc.name}
                                            onClick={() => {
                                                const data: DocumentStepData = {
                                                    id: doc.id,
                                                    name: doc.name,
                                                };

                                                documentGenerationStepsActions.apply({
                                                    id: GenerationSteps.DOCUMENT,
                                                    isComplete: true,
                                                    data,
                                                });

                                                navigate(ROUTES.type.path);
                                            }}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    </DataLoader>
                </ContentWithStickySidebar>

            </div>
        </div>
    );
}

export default ChooseDocumentPage;
