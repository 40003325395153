import React from 'react';
import { useNavigate } from 'react-router-dom';

import classnames from 'classnames/bind';

import ROUTES from '@CONSTANTS/ROUTES.constant';
import GenerationSteps from '@CONSTANTS/GENERATION_STEPS.constant';

import {
    AreaStep, CountryStep, CountryStepData, RegionStep,
} from '@REDUCERS';

import useDocumentGenerationSteps from '@HOOKS/store/useDocumentGenerationSteps';
import { useGetAllCountries } from '@SERVICES';

import Grid from '@COMPONENTS/SHARED/Grid';
import SquareButton from '@COMPONENTS/COMMON/buttons/SquareButton';
import DataLoader from '@COMPONENTS/SHARED/DataLoader';
import ContentWithStickySidebar from '@COMPONENTS/SHARED/ContentWithStickySidebar';
import StepSidebar from '@COMPONENTS/SIDEBARS/StepSidebar';

import styles from './Country.module.scss';

const cx: CX = classnames.bind(styles);

function CountryPage() {
    const {
        actions: documentGenerationStepsActions,
        services: documentGenerationStepsServices,
    } = useDocumentGenerationSteps(() => true);

    const regionStep: RegionStep = documentGenerationStepsServices.getStepData(GenerationSteps.REGION);
    const areaStep: AreaStep = documentGenerationStepsServices.getStepData(GenerationSteps.AREA);

    const {
        data: countriesData = [], isLoading, isError, error,
    } = useGetAllCountries({
        region: regionStep.data?.id,
        area: areaStep.data?.id,
    });

    const navigate = useNavigate();

    function handleClick(id: number, name: string) {
        const data: CountryStepData = {
            id,
            name,
        };

        documentGenerationStepsActions.apply({
            id: GenerationSteps.COUNTRY,
            isComplete: true,
            data,
        });

        navigate(ROUTES.options.path);
    }

    function getHighlighted(id: number) {
        const step: CountryStep = documentGenerationStepsServices.getStepData(GenerationSteps.COUNTRY);

        return step.data?.id === id;
    }

    return (
        <div className={cx('page')}>
            <div className="container max-constraint center">
                <ContentWithStickySidebar
                    sidebar={<StepSidebar />}
                    offsetTop={130}
                    offsetBottom={100}
                >
                    <h1>Country</h1>
                    <DataLoader
                        isLoading={isLoading}
                        isError={isError}
                        error={error}
                    >
                        <Grid
                            hasSidebar
                        >
                            {
                                countriesData.map((country) => {
                                    const key = country.id;

                                    const highlighted = getHighlighted(country.id);

                                    return (
                                        <SquareButton
                                            key={key}
                                            highlighted={highlighted}
                                            title={country.name}
                                            onClick={() => handleClick(country.id, country.name)}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    </DataLoader>
                </ContentWithStickySidebar>
            </div>
        </div>
    );
}

export default CountryPage;
